:root {
    --html-padding-top: 80px;
    --html-padding-right: 5%;
    --html-padding-left: 9%;
    --html-gap: 100px;

    /*--footer-font-size: 28px;*/

    --header-size: 51px;
    --header-line-height: 62px;
    --heading-subtitle-font-size: 28px;

    --public-resources-gap: 40px;

    /*--section-title-font-size: 38px;*/
    /*--section-body-font-size: 28px;*/

    /*--nested-section-title-font-size: 32px;*/

    --statistics-item-icon-width: 30px;
    --statisitcs-item-icon-height: 30px;

    --product-description-font-size: 26px;
    --product-features-title-font-size: 26px;

    --feature-padding: 40px;
    --feature-description-font-size: 22px;
    --feature-icon-width: 100px;
    --feature-icon-height: 100px;
    --product-icon-width: 36px;
    --product-icon-height: 36px;

    --testimony-font-size: 21px;
    --testimony-icon-width: 16px;
    --testimony-icon-height: 16px;
    --testimony-padding: 0;

    --knowledge-sections-title-size: 32px;
    --knowledge-sections-body-size: 26px;
}

p {
    margin: 0;
}

@media screen and (max-width: 64em) {
    :root {
        --html-padding-top: 60px;
        --html-padding-right: 5%;
        --html-padding-left: 5%;
        --html-gap: 60px;

        --public-resources-gap: 20px;

        /* 51 -> 38 */
        --header-size: 28px;
        --header-line-height: 39px;

        /* 28 -> 21 */
        --heading-subtitle-font-size: 21px;

        /*  26 -> 16 */
        --product-description-font-size: 16px;
        /*  26 -> 16 */
        --product-features-title-font-size: 16px;

        --feature-padding: 20px;
        /*  22 -> 18 */
        --feature-description-font-size: 16px;

        /* 100 -> 50 */
        --feature-icon-width: 50px;
        --feature-icon-height: 50px;

        --product-icon-width: 20px;
        --product-icon-height: 20px;

        --statistics-item-icon-width: 20px;
        --statisitcs-item-icon-height: 20px;

        --testimony-font-size: px;
        --testimony-icon-width: 12px;
        --testimony-icon-height: 12px;
        --testimony-padding: 0;


        --knowledge-sections-title-size: var(--nested-section-title-font-size);
        /* similar to testimony's font size; could be tweaked later if needed */
        --knowledge-sections-body-size: 16px;
    }

    .background {
        background-size: unset;
    }
}

#heading {
    gap: 16px;
}

#heading .header {
    font-size: var(--header-size);
    font-weight: 400;
    line-height: var(--header-line-height);
    letter-spacing: 0;
    text-align: left;

    margin-bottom: 16px;
}

.font-700 {
    font-weight: 700;
}

.font-600 {
    font-weight: 600;
}

.font-500 {
    font-weight: 500;
}

.font-400 {
    font-weight: 400;
}

.underline {
    text-decoration: underline;
}

#statistics {
    display: flex;
    flex-direction: column;

    /*gap: 16px;*/
}

#statistics > .body {
    display: flex;
    flex-direction: column;

    font-size: var(--section-body-font-size);
    border-left: black 1px solid;

    padding-left: 20px;
    gap: 5px;
}

#statistics > .body > .item {
    display: flex;
    align-items: center;

    gap: 5px;
}

#statistics .item .icon {
    width: var(--statistics-item-icon-width);
    height: var(--statisitcs-item-icon-height);
}

#public-resources {
    display: flex;
    flex-direction: column;

    /*gap: var(--public-resources-gap);*/
}

#public-resources > .body {}

.product > .title {
    display: flex;
    gap: 10px;

    font-size: var(--nested-section-title-font-size);

    align-items: center;
    padding-bottom: 5px;
}

.product > .title > .icon {
    width: var(--product-icon-width);
    height: var(--product-icon-height);
}

.product > .description {
    font-size: var(--product-description-font-size);
    font-weight: 400;
}

.product > .description, .features, .testimonials {
    padding-bottom: 10px;
}

.product > .features {
    font-size: var(--product-features-title-font-size);
    font-weight: 300;
}

.product > .item > .title {
    font-size: var(--product-features-title-font-size);
    font-weight: 300;
}

.item > .body {
    border-left: black 1px solid;

    gap: 40px;
    padding: 20px 40px 20px 40px;
}

.feature {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;

    padding: var(--feature-padding);
    gap: 10px;

    /*height: 100%;*/
}

.feature,
.feature > .icon,
.feature > .icon > img,
.feature > *,
.features > .body > * {
    background-color: white;
}

.features > .body > * {
    background-color: white;
    box-shadow: 0 0 4px 0 #00000040;
}

/*.product > .item > * > * {*/
/*    background-color: white;*/
/*}*/

.feature > div.icon {
    display: flex;
    justify-content: center;
}

.feature > .icon > img {
    width: var(--feature-icon-width);
    height: var(--feature-icon-height);
}

.feature > .title {
    line-height: 31px;
    font-weight: 500;
}

.feature > .description {
    font-weight: 400;
}

.feature > .description {
    font-size: var(--feature-description-font-size);
    line-height: 26px;
    font-weight: 400;
}

/*.testimonials > .body {*/
/*    background-color: white;*/
/*}*/

/*.testimony {*/
/*    box-shadow: 0 0 4px 0 #00000040;*/
/*}*/

/*.testimonials > .body > *,*/
/*.testimony, .testimony > **/
/*{*/
/*    background-color: white;*/
/*}*/

.testimony {
    display: flex;
    flex-direction: column;

    gap: 10px;
    padding: var(--testimony-padding);
}

.testimony > .stars {
    display: flex;
    gap: 2.5px;
}

.testimony > .stars > .icon {
    width: var(--testimony-icon-width);
    height: var(--testimony-icon-height);
}

.testimony > .description {
    font-size: var(--testimony-font-size);
}

.testimony > .from {
    font-weight: 300;
    font-size: 16px;
}

#knowledge {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

#knowledge > .body {
    /*display: flex;*/

    gap: 15px;
    font-size: var(--knowledge-sections-title-size);
    font-weight: 300;

    /*margin-bottom: 20px;*/
}

#knowledge > .body > * {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#knowledge > .body > * > .body {
    border-left: black 1px solid;
}

#expertise > .body > ul,
#other-projects > .body > ul,
#languages-and-tools > .body > ul {
    margin: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

li::marker {
    font-size: 1rem;
}

#expertise > .body > li::marker,
#other-projects > .body > li::marker,
#languages-and-tools > .body > li::marker {
    width: 5px;
}

#expertise > .body,
#other-projects > .body,
#languages-and-tools > .body {
    font-size: var(--knowledge-sections-body-size);
    /*font-weight: 400;*/
    /*padding: 0 20px 0 20px;*/
}

#heading .subtitle {
    font-size: var(--heading-subtitle-font-size);
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0;
    text-align: left;
}

#services {
    display: flex;
    flex-direction: column;

    gap: 10px;
}