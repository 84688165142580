ul {
  margin: 0;
  margin-top: 5px;
  
  display: flex;
  flex-direction: column;
  gap: 2.5px;
}

li::marker {
  font-size: 1rem;
}