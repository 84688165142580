:root {
    --footer-font-size: 28px;

    --section-title-font-size: 38px;
    --section-body-font-size: 28px;

    --nested-section-title-font-size: 32px;
}

@media screen and (max-width: 64em) {
    :root {
        --footer-font-size: 16px;

        /* 38 -> 28 */
        --section-title-font-size: 28px;
        --section-body-font-size: 18px;

        /* 32 -> 22 */
        --nested-section-title-font-size: 22px;
    }
}

html {
    overflow-x: hidden;
}

html, body, #root, #main {
    height: 100%;
    width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

#main {
    height: auto;
    flex: 1 0 auto;
}

#header {
    .item {
        align-items: center;
    }

    .items {
        .item {
            display: flex;

            justify-content: center;
        }

        > .box {
            background-color: black;
            color: white;

            padding: 10px 20px 10px 20px;
            border-radius: 30px;
        }

        display: flex;
        justify-content: center;
        gap: 10px;
    }

    display: flex;
    justify-content: space-between;

    align-items: center;
    justify-items: center;

    font-size: var(--footer-font-size);

    background-color: white;

    box-shadow: 0 0 4px 0 #00000040;

    padding: 20px var(--html-padding-right) 20px var(--html-padding-left); /* top right bottom left */
}

#body {
    display: flex;
    flex-direction: column;

    /*padding: 120px 120px 240px 240px;  !* top right bottom left *!*/
    margin: var(--html-padding-top) var(--html-padding-right) 160px var(--html-padding-left); /* top right bottom left */
    gap: var(--html-gap);
}

.background {
    background-image: url(../assets/topography.svg);

    height: 1000vh;
    width: 100%;

    opacity: 0.03;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;

    z-index: -1;
}

.section {
    > .title {
        > .icon {
            width: var(--section-title-font-size);
        }
        display: flex;

        font-weight: 300;
        font-size: var(--section-title-font-size);
        gap: 10px;

        align-items: center;

        padding-bottom: 16px;
    }

    > .body {
        // nested sections
        > .section {
            > .title {
                font-size: var(--nested-section-title-font-size);
            }

            > .body {
                font-size: var(--section-body-font-size);
            }

            display: flex;
            flex-direction: column;

            gap: 16px;
        }

        font-size: var(--section-body-font-size);
        font-weight: 300;
    }
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

button:hover {
    opacity: 0.7;
}

.link:hover {
    color: rgb(255, 255, 255, 0.9);
}

.box {
    background-color: white;
    color: black;

    padding: 10px 20px 10px 20px;
    border-radius: 30px;
}

.link {
    color: inherit;
    text-decoration: inherit;
}

#footer {
    > .body {
        .section {
            .title {
                font-weight: 300;
                font-size: var(--heading-subtitle-font-size);
            }

            .body {
                font-size: var(--testimony-font-size);
                font-weight: 200;
            }
            display: flex;
            flex-direction: column;

            gap: 10px;
        }

        display: flex;
        gap: 10px;

        padding: 40px 20px 40px 20px;
    }

    display: flex;
    flex-direction: column;

    flex-shrink: 0;

    justify-content: center;
    align-items: center;

    background-color: black;
    color: white;

    box-shadow: 0 0 4px 0 #00000040;

    .credits {
        padding: 20px 0 20px 0;
        font-weight: 300;
        font-size: var(--testimony-font-size);
    }
}